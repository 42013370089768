"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
// Check webp support
!function (e, n, A) {
  function o(e) {
    var n = u.className,
      A = Modernizr._config.classPrefix || "";
    if (c && (n = n.baseVal), Modernizr._config.enableJSClass) {
      var o = new RegExp("(^|\\s)" + A + "no-js(\\s|$)");
      n = n.replace(o, "$1" + A + "js$2");
    }
    Modernizr._config.enableClasses && (n += " " + A + e.join(" " + A), c ? u.className.baseVal = n : u.className = n);
  }
  function t(e, n) {
    return _typeof(e) === n;
  }
  function a() {
    var e, n, A, o, a, i, l;
    for (var f in r) if (r.hasOwnProperty(f)) {
      if (e = [], n = r[f], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (A = 0; A < n.options.aliases.length; A++) e.push(n.options.aliases[A].toLowerCase());
      for (o = t(n.fn, "function") ? n.fn() : n.fn, a = 0; a < e.length; a++) i = e[a], l = i.split("."), 1 === l.length ? Modernizr[l[0]] = o : (!Modernizr[l[0]] || Modernizr[l[0]] instanceof Boolean || (Modernizr[l[0]] = new Boolean(Modernizr[l[0]])), Modernizr[l[0]][l[1]] = o), s.push((o ? "" : "no-") + l.join("-"));
    }
  }
  function i(e, n) {
    if ("object" == _typeof(e)) for (var A in e) f(e, A) && i(A, e[A]);else {
      e = e.toLowerCase();
      var t = e.split("."),
        a = Modernizr[t[0]];
      if (2 == t.length && (a = a[t[1]]), "undefined" != typeof a) return Modernizr;
      n = "function" == typeof n ? n() : n, 1 == t.length ? Modernizr[t[0]] = n : (!Modernizr[t[0]] || Modernizr[t[0]] instanceof Boolean || (Modernizr[t[0]] = new Boolean(Modernizr[t[0]])), Modernizr[t[0]][t[1]] = n), o([(n && 0 != n ? "" : "no-") + t.join("-")]), Modernizr._trigger(e, n);
    }
    return Modernizr;
  }
  var s = [],
    r = [],
    l = {
      _version: "3.6.0",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function on(e, n) {
        var A = this;
        setTimeout(function () {
          n(A[e]);
        }, 0);
      },
      addTest: function addTest(e, n, A) {
        r.push({
          name: e,
          fn: n,
          options: A
        });
      },
      addAsyncTest: function addAsyncTest(e) {
        r.push({
          name: null,
          fn: e
        });
      }
    },
    Modernizr = function Modernizr() {};
  Modernizr.prototype = l, Modernizr = new Modernizr();
  var f,
    u = n.documentElement,
    c = "svg" === u.nodeName.toLowerCase();
  !function () {
    var e = {}.hasOwnProperty;
    f = t(e, "undefined") || t(e.call, "undefined") ? function (e, n) {
      return n in e && t(e.constructor.prototype[n], "undefined");
    } : function (n, A) {
      return e.call(n, A);
    };
  }(), l._l = {}, l.on = function (e, n) {
    this._l[e] || (this._l[e] = []), this._l[e].push(n), Modernizr.hasOwnProperty(e) && setTimeout(function () {
      Modernizr._trigger(e, Modernizr[e]);
    }, 0);
  }, l._trigger = function (e, n) {
    if (this._l[e]) {
      var A = this._l[e];
      setTimeout(function () {
        var e, o;
        for (e = 0; e < A.length; e++) (o = A[e])(n);
      }, 0), delete this._l[e];
    }
  }, Modernizr._q.push(function () {
    l.addTest = i;
  }), Modernizr.addAsyncTest(function () {
    function e(e, n, A) {
      function o(n) {
        var o = n && "load" === n.type ? 1 == t.width : !1,
          a = "webp" === e;
        i(e, a && o ? new Boolean(o) : o), A && A(n);
      }
      var t = new Image();
      t.onerror = o, t.onload = o, t.src = n;
    }
    var n = [{
        uri: "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=",
        name: "webp"
      }, {
        uri: "data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==",
        name: "webp.alpha"
      }, {
        uri: "data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
        name: "webp.animation"
      }, {
        uri: "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=",
        name: "webp.lossless"
      }],
      A = n.shift();
    e(A.name, A.uri, function (A) {
      if (A && "load" === A.type) for (var o = 0; o < n.length; o++) e(n[o].name, n[o].uri);
    });
  }), a(), o(s), delete l.addTest, delete l.addAsyncTest;
  for (var p = 0; p < Modernizr._q.length; p++) Modernizr._q[p]();
  e.Modernizr = Modernizr;
}(window, document);
var formLocalStorageKey = "_e_indiain_";
var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

// change attribute in some element for example change href in  a
function changeObjAttrybute(elem, attrName, attrVal) {
  elem.setAttribute(attrName, attrVal);
}

// change content in some element
function changeObjText(elem, elemVal) {
  elem.innerHTML = elemVal;
}
var translations;
function getTranslationsFromLang(keys) {
  if (typeof translations === "undefined") {
    var currLang = document.querySelector('body').getAttribute('data-current-lang');
    var formData = 'params=[' + keys.map(function (key) {
      return "[\"".concat(key, "\", \"").concat(currLang, "\"]");
    }).join(',').replace(/%20/g, '+') + ']';
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/langTranslation/', false);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(formData);
    if (xhr.status === 200) {
      translations = JSON.parse(xhr.responseText);
      showPageEmail(translations['Menu 3 Link 2']);
    }
  }
}
getTranslationsFromLang(['form field warning', 'form date callendar button prev', 'form date callendar button next', 'Menu 3 Link 2', 'form label nonlatin error']);
function showPageEmail(mailName) {
  var email = document.querySelector('.support-email');
  if (email) {
    email.innerHTML = mailName;
    email.setAttribute('href', 'mailto:' + mailName);
  }
}
function send_to_logger(data) {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '/logger.php', true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send("data=".concat(data));
}
function getPhoneNumbers() {
  var formData = new FormData();
  formData.append('language', document.body.dataset.currentLang);
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '/get-phone-number/', true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send("language=".concat(document.body.dataset.currentLang));
  xhr.onreadystatechange = function (e) {
    if (this.readyState === 4) {
      if (this.status === 200) {
        var data = JSON.parse(this.responseText);
        if (data.status) {
          showPagePhone(data.numbers);
        } else {
          send_to_logger("JSERR " + window.location.href + "main.js:getPhoneNumbers()\n\n Phone numbers access has been failed");
        }
      } else {
        send_to_logger("JSERR " + window.location.href + "main.js:getPhoneNumbers()\n\n Phone numbers access has been failed");
      }
    }
  };
}
function showPagePhone(phoneNumbers) {
  if (typeof phoneNumbers[0] !== "undefined") {
    var primaryPhone = phoneNumbers[0];
    // phone footer
    var footerPhone = document.querySelector('.footer-phone');
    if (footerPhone) {
      footerPhone.setAttribute('href', 'tel:' + primaryPhone.raw_number);
      footerPhone.innerText = primaryPhone.number;
    }
  }
}
function doYouNeedTableNormalize() {
  var currentWidth = window.innerWidth;
  var elements = document.querySelectorAll('.countries-table table td:empty');
  if (currentWidth < 744 || supportsTouch && currentWidth <= 1024) {
    for (var i; i < elements.length; i++) {
      elements[i].style.borderTop = '0';
    }
  } else {
    for (var _i; _i < elements.length; _i++) {
      eelements[_i].style.borderTop = '';
    }
  }
}
getPhoneNumbers();
doYouNeedTableNormalize();

// window resize for do you need table cosmetics change
window.addEventListener('resize', function () {
  doYouNeedTableNormalize();
});
languagesMenu();
if (typeof Storage !== "undefined") {
  var lastDate = localStorage.getItem(formLocalStorageKey + '_date');
  if (lastDate !== null && parseInt(lastDate) + 5 * 24 * 60 * 60 * 1000 < Date.now()) {
    localStorage.removeItem(formLocalStorageKey);
    localStorage.removeItem(formLocalStorageKey + '_date');
  }
}
var initialLanguages;
function languagesMenu() {
  var $languagesMenu = document.querySelector('.languages .list-container');
  var slide = function slide() {
    setTimeout(function () {
      if ($languagesMenu.classList.includes('hovered')) $languagesMenu.style.height = '100%';else $languagesMenu.style.height = '0';
    }, 100);
  };
  if (window.innerWidth > 1024) {
    if (document.querySelector('.languages .current .wrapper')) {
      document.querySelector('.languages .current .wrapper').addEventListener('mouseenter', function () {
        $languagesMenu.classList.add('hovered');
        slide();
      });
      document.querySelector('.languages .current .wrapper').addEventListener('mouseleave', function () {
        $languagesMenu.removeClass('hovered');
        slide();
      });
    }
    if ($languagesMenu) {
      $languagesMenu.addEventListener('mouseenter', function () {
        $languagesMenu.classList.add('hovered');
        slide();
      });
      $languagesMenu.addEventListener('mouseleave', function () {
        $languagesMenu.classList.remove('hovered');
        slide();
      });
    }
  } else {
    if (document.querySelector('.languages .current .wrapper')) {
      document.querySelector('.languages .current .wrapper').addEventListener('click', function () {
        $languagesMenu.classList.toggle('active');
      });
    }
  }
}

// LANG MENU START
window.addEventListener('click', function (e) {
  if (document.querySelector('.langList') && _typeof(e) !== undefined && _typeof(e.target) !== undefined && !e.target.classList !== undefined && !e.target.classList.contains('language-switcher') && !e.target.parentElement.classList !== undefined && !e.target.parentElement.classList.contains('currentLang')) {
    document.querySelector('.langList').classList.add('hidden');
  }
});
if (document.querySelector('.language-switcher')) {
  document.querySelector('.language-switcher').addEventListener('click', function () {
    document.querySelector('.langList').classList.toggle('hidden');
  });
}
// LANG MENU END

function slideUp(target) {
  target.style.height = '0';
}
function slideDown(target) {
  target.style.height = target.dataset.height;
}
function slideToggle(target) {
  if (target.getAttribute('aria-hidden') === 'false') {
    slideUp(target);
    target.setAttribute('aria-hidden', 'true');
  } else {
    slideDown(target);
    target.setAttribute('aria-hidden', 'false');
  }
}
function registerHeight(target) {
  target.style.visibility = 'hidden';
  target.style.height = 'auto';
  target.dataset.height = window.getComputedStyle(target).height;
  target.style.visibility = 'visible';
  target.style.height = '0px';
}
function accordionMenu() {
  var dropdownButtons = document.querySelectorAll('.dropdown-btn');
  dropdownButtons.forEach(function (button) {
    button.addEventListener('click', function (e) {
      button.classList.toggle('active');
      button.nextElementSibling.classList.toggle('hidden');
    });
  });
}
document.addEventListener('DOMContentLoaded', function () {
  accordionMenu();
  if (document.querySelector('#mobile-navigation-btn')) {
    window.addEventListener('resize', function () {
      if (window.innerWidth > 1100) {
        document.querySelector('#mobile-navigation-btn > .hamburger-menu-icon').classList.remove('close');
        document.querySelector('.mobile-navigation-wrapper').style.display = 'none';
      } else {
        document.querySelector('.mobile-navigation-wrapper').style.removeProperty('display');
      }
    });
    if (document.querySelector('.mobile-navigation-wrapper')) {
      registerHeight(document.querySelector('.mobile-navigation-wrapper'));
    }
    document.querySelector('#mobile-navigation-btn').addEventListener('click', function () {
      document.querySelector('#mobile-navigation-btn > .hamburger-menu-icon').classList.toggle('close');
      slideToggle(document.querySelector('.mobile-navigation-wrapper'));
    });
    document.querySelector('.menu-item-has-children', function () {
      document.querySelector('#mobile-navigation-btn > .hamburger-menu-icon').classList.remove('close');
      slideToggle(document.querySelector('#mobile-navigation-btn > .hamburger-menu-icon'));
    });
  }
});